import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-editar-organograma',
  templateUrl: './editar-organograma.component.html',
  styleUrls: ['./editar-organograma.component.scss']
})

export class EditarOrganogramaComponent implements OnInit, AfterViewInit {
  @ViewChild('myTree', { static: true }) myTree: jqxTreeComponent;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private organogramaService: OrganogramaService) {
  }
  resultado: Array<any> = [];
  filhos: Array<any> = [];
  encontrado: Array<any> = [];
  organogramasPorId: any;
  statusOrganograma: string;
  routeSubscription: Subscription;
  codigoOrganograma: number;
  codigoUsuario: String;
  obj: any = {};
  loading: boolean = false;
  dataFormatada: any;
  listaOrganograma: any;
  listaOrganogramaNiveis: any;
  idNivel: any;
  selectedChild: any;
  desmarcou: boolean = false;
  searchParams = {
    ativo: true,
    pagina: 1,
    total_pagina: 10
  }
  editarOrganogramaForm = this.formBuilder.group({
    nome: '',
    novoItem: ['', Validators.compose([Validators.minLength(5), Validators.maxLength(60)])],
    dataCriacao: '',
    ativo: true
  });

  ngOnInit(): void {
    let session = JSON.parse(localStorage.getItem('session'));
    this.codigoUsuario = session.usuario.codigo;

    this.loading = true;
    this.routeSubscription = this.route.params.subscribe(params => {
      this.codigoOrganograma = params['id'];
      this.organogramaService.getOrganogramaData(this.codigoOrganograma)
        .subscribe(data => {
          this.dataFormatada = data['resultado'][0]['dataCriacao'];
          this.dataFormatada = moment(this.dataFormatada).format('DD/MM/YYYY');

          this.editarOrganogramaForm.patchValue({
            nome: data['resultado'][0]['text'],
            dataCriacao: this.dataFormatada,
            ativo: data['resultado'][0]['ativo']
          })
        })
    })
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  data: any[] = [];
  source = {
    datatype: 'json',
    datafields: [
      { name: 'id' },
      { name: 'parentId' },
      { name: 'text' }
    ],
    id: 'id',
    localdata: null
  };

  dataAdapter = new jqx.dataAdapter(this.source);

  records: any = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label' }]);

  AddOnClick(): void {
    let selectedItem = this.myTree.getSelectedItem();
    if (selectedItem == null && this.data.length == 0) {
      this.myTree.addTo({ label: this.editarOrganogramaForm.controls.novoItem.value, id: this.data.length + 1 }, null);
      this.data.push({ 'id': this.data.length + 1, 'text': this.editarOrganogramaForm.controls.novoItem.value, 'parentId': null });
      this.myTree.render();
    }
    else if (selectedItem != null && this.editarOrganogramaForm.controls.novoItem.value.length >= 5) {
      this.myTree.addTo({ label: this.editarOrganogramaForm.controls.novoItem.value, id: this.data.length + 1 }, selectedItem.element);
      this.data.push({ 'id': this.data.length + 1, 'text': this.editarOrganogramaForm.controls.novoItem.value, 'parentId': selectedItem['id'] });
      this.myTree.render();
    } else if (selectedItem == null) {
      Swal.fire({
        title: 'Ops!',
        text: 'Selecione um item antes de adicionar',
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    } else if (this.editarOrganogramaForm.controls.novoItem.value.length <= 5) {
      Swal.fire({
        title: 'Ops!',
        text: 'Nome do item deve ter mais de 5 letras',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  RemoveOnClick(): void {
    let selectedItem = this.myTree.getSelectedItem();
    if (selectedItem != null) {
      this.resultado = this.data.filter(element => element.parentId == selectedItem.element.id);
      let elemento = this.data.filter(element => element.id == selectedItem.element.id);
      for (let i = 0; i < this.data.length; i++) {
        if (elemento[0].id == this.data[i].id) {
          this.data.splice(i, 1);
        }
      }
      if (this.resultado) {
        this.filhos = this.filhos.concat(this.resultado);
        this.resultado = [];
        for (let i = 0; i < this.filhos.length; i++) {
          this.resultado = this.data.filter(element => element.parentId == this.filhos[i].id);
          this.filhos = this.filhos.concat(this.resultado);
          this.resultado = [];
        }
        for (let i = 0; i < this.filhos.length; i++) {
          for (let j = 0; j < this.data.length; j++) {
            if (this.filhos[i].id == this.data[j].id) {
              this.data.splice(j, 1);
            }
          }
        }
        this.resultado = [];
        this.filhos = [];
      }

      this.myTree.removeItem(selectedItem.element);
    }
  }

  ExpandAllOnClick(): void {
    this.myTree.expandAll();
  }

  CollapseOnClick(): void {
    this.myTree.collapseAll();
  }

  dragStart(item): boolean {
    return true;
  }

  dragEnd(item, dropItem, args, dropPosition, tree): boolean {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].id == item.owner.owner.selectedItem.id) {
        this.data[i].parentId = item.owner.owner.selectedItem.parentId;
      }
    }

    return true;
  }

  ajuda() {
    Swal.fire({
      text: 'Digite o nome do item a ser criado e selecione um item no organograma para adicionar.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  }

  isFormInvalid() {
    if (this.editarOrganogramaForm.valid) {
      return false;
    } else {
      return true;
    }
  }

  private loadData(): void {
    this.organogramaService.getOrganogramaData(this.codigoOrganograma).subscribe(data => {
      this.data = data['resultado'];
      setTimeout(() => {
        this.source.localdata = data;
        this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
        this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label' }]);
        this.myTree.refresh();
        this.loading = false;
      }, 1000)
    });
  }

  desistir() {
    Swal.fire({
      text: 'Tem certeza que deseja desistir? Todos os dados serão perdidos',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(res => {
      if (res.value) {
        this.router.navigate(['/configuracao/ambiente/consultar-organograma/1'])
      }
    });
  }

  salvar() {
    if (this.data.length > 0) {
      this.obj = this.data;
      if (this.editarOrganogramaForm.controls.ativo.value == true) {
        this.organogramaService.editOrganograma(this.obj, this.codigoOrganograma)
          .subscribe(
            res => {
              Swal.fire({
                title: 'Alterações salvas!',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then(() => {
                this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
              });
            }, err => {
              Swal.fire({
                title: 'Ops!',
                text: 'Não foi possível editar o Organograma',
                icon: 'error',
                confirmButtonText: 'OK'
              }).then(() => {
                console.log('this.obj e this.codigoOrganograma', this.obj, this.codigoOrganograma);
              });
            });
      } else {
        if (this.data.length > 0) {
          this.organogramaService.getOrganogramasEmUso(this.codigoOrganograma).subscribe((res: any) => {
            if (res.resultado == true) {  // inativação e transferência de responsabilidade
              Swal.fire({
                html: 'Prezado, o organograma só poderá ser inativado caso a sua responsabilidade seja transferida para outro(s) organograma(s). <br/> <br/>Deseja prosseguir?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Prosseguir',
                cancelButtonText: 'Desistir',
                onOpen: () => {
                  let botaoDesistir = document.querySelector('.swal2-cancel') as HTMLButtonElement;
                  botaoDesistir.addEventListener('click', () => {
                    this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                  });
                }
              }).then(res => {
                if (res.value) {
                  this.organogramaService.getOrganogramasPortabilidade(this.codigoOrganograma).subscribe((data: any) => {
                    this.listaOrganograma = data['resultado'];
                    let html = '';
                    this.organogramasPorId = {};
                    let seqniveisSelecionados = [];
                    for (let i = 0; i < this.listaOrganograma.length; i++) {
                      let organograma = this.listaOrganograma[i];
                      let id = organograma.sequencia;
                      html += `<input type="checkbox" id="${id}" class="checkbox" value="${id}" name="organograma" />
            <label for="${id}" class="mr-3">${organograma.dscOrganograma}</label><br>`;
                      if (organograma.niveis) { 
                        for (let nivel of organograma.niveis) {
                          let idNivel = nivel.sequenciaNivel;
                          html += `<input type="checkbox" id="${idNivel}" class="checkbox child-checkbox" value="${idNivel}" name="nivel" data-parent="${id}" style="margin-left: 15px;" />
                <label for="${idNivel}" class="mr-3">${nivel.dscOrganogramaNivel}</label><br>`;
                        }
                      }
                      this.organogramasPorId[id] = organograma;
                      if (String(id) === String(this.codigoOrganograma)) {
                        continue;
                      }
                    }

                    Swal.fire({
                      title: 'Transferência de responsabilidade',
                      html: `<div style="max-height: 200px; overflow-y: auto; text-align: left; margin-left: 50px;">${html}</div>`,
                      icon: 'info',
                      showCancelButton: true,
                      cancelButtonText: 'Desistir',
                      confirmButtonText: 'Salvar Alterações',
                      preConfirm: () => {
                        let IdNovoOrganograma = (document.querySelector('input[name="organograma"]:checked') as HTMLInputElement).value;
                        return IdNovoOrganograma;
                      },
                      onOpen: () => {
                        let botao = document.querySelector('.swal2-confirm') as HTMLButtonElement;
                        botao.disabled = true;
                        let checkboxes = document.querySelectorAll('input[type=checkbox][name="organograma"]');
                        checkboxes.forEach((checkbox: any) => {
                          let nivelCheckboxes = document.querySelectorAll('input[type=checkbox][name="nivel"]');

                          checkbox.addEventListener('change', () => {
                            checkboxes.forEach((otherCheckbox: any) => {
                              if (otherCheckbox !== checkbox) {
                                (otherCheckbox as HTMLInputElement).checked = false;
                              }
                            });

                            for (let i = 0; i < checkboxes.length; i++) {
                              if ((checkboxes[i] as HTMLInputElement).checked) {
                                botao.disabled = false;
                                return;
                              } else {
                                botao.disabled = true;
                              }
                            }
                          });

                          checkbox.addEventListener('change', () => {
                            let seqNivel = Number((checkbox as HTMLInputElement).value);

                            let parentId = (checkbox as HTMLInputElement).getAttribute('data-parent');
                            let parentObj = this.listaOrganograma.find(org => org.sequencia === Number(parentId));

                            let nivelObj;
                            for (let org of this.listaOrganograma) {
                              for (let nivel of org.niveis) {
                                if (nivel.sequenciaNivel === seqNivel) {
                                  nivelObj = nivel;
                                  break;
                                }
                              }
                              if (nivelObj) break;
                            }

                            if ((checkbox as HTMLInputElement).checked) {
                              seqniveisSelecionados.push(nivelObj);
                              if (parentObj) {
                                let parentCheckbox = document.getElementById(String(parentObj.sequencia)) as HTMLInputElement;
                                if (parentCheckbox && !parentCheckbox.checked) {
                                  parentCheckbox.checked = true;
                                  botao.disabled = false;
                                }
                              }
                              nivelCheckboxes.forEach((otherCheckbox) => {
                                let otherParentId = (otherCheckbox as HTMLInputElement).getAttribute('data-parent');
                                if (otherCheckbox !== checkbox && otherParentId !== parentId) {
                                  (otherCheckbox as HTMLInputElement).checked = false;
                                }
                              });
                            }
                          });
                        }); ////
                        let nivelCheckboxes = document.querySelectorAll('input[type=checkbox][name="nivel"]');

                        nivelCheckboxes.forEach((checkbox: any) => {

                          checkbox.addEventListener('change', () => {
                            if (checkbox.getAttribute('name') === !'organograma') {
                              nivelCheckboxes.forEach((otherCheckbox: any) => {
                                if (otherCheckbox !== checkbox) {
                                  (otherCheckbox as HTMLInputElement).checked = false;
                                  botao.disabled = true;
                                }
                              });
                            }

                            for (let i = 0; i < checkboxes.length; i++) {
                              if ((nivelCheckboxes[i] as HTMLInputElement).checked) {
                                return;
                              }
                            }
                          });

                          checkbox.addEventListener('change', () => {
                            let seqNivel = Number((checkbox as HTMLInputElement).value);
                            let parentId = (checkbox as HTMLInputElement).getAttribute('data-parent');
                            let parentObj = this.listaOrganograma.find(org => org.sequencia === Number(parentId));

                            let nivelObj;
                            for (let org of this.listaOrganograma) {
                              for (let nivel of org.niveis) {
                                if (nivel.sequenciaNivel === seqNivel) {
                                  nivelObj = nivel;
                                  break;
                                }
                              }
                              if (nivelObj) break;
                            }

                            if ((checkbox as HTMLInputElement).checked) {
                              seqniveisSelecionados.push(nivelObj);
                              if (parentObj) {
                                let parentCheckbox = document.getElementById(String(parentObj.sequencia)) as HTMLInputElement;
                                if (parentCheckbox && !parentCheckbox.checked) {
                                  parentCheckbox.checked = true;
                                  botao.disabled = false;
                                }
                              }
                              nivelCheckboxes.forEach((otherCheckbox) => {
                                let otherParentId = (otherCheckbox as HTMLInputElement).getAttribute('data-parent');
                                if (otherCheckbox !== checkbox && otherParentId !== parentId) {
                                  (otherCheckbox as HTMLInputElement).checked = false;
                                }
                              });

                              nivelCheckboxes.forEach((checkbox) => {
                                checkbox.addEventListener('change', () => {
                                  checkboxes.forEach((otherCheckbox: any) => {
                                    (otherCheckbox as HTMLInputElement).checked = false;
                                    let parentId = (checkbox as HTMLInputElement).getAttribute('data-parent');
                                    let parentObj = this.listaOrganograma.find(org => org.sequencia === Number(parentId));
                                    let parentCheckbox = document.getElementById(String(parentObj.sequencia)) as HTMLInputElement;
                                    parentCheckbox.checked = true;
                                  });
                                })
                              })
                            }
                          });
                        });
                        let botaoDesistir = document.querySelector('.swal2-cancel') as HTMLButtonElement;
                        botaoDesistir.addEventListener('click', () => {
                          this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                        });
                      }
                    }).then((result) => {
                      if (result.value) {
                        let IdNovoOrganograma = result.value;
                        let novoOrganograma = JSON.parse(JSON.stringify(this.organogramasPorId[IdNovoOrganograma]));
                        novoOrganograma.niveis = seqniveisSelecionados;
                        seqniveisSelecionados.forEach(item => {
                          if (item == null || item == undefined)
                            novoOrganograma.niveis = [];
                        });
                        this.organogramaService.desativarOrganograma(this.codigoOrganograma, novoOrganograma)
                          .subscribe(
                            res => {
                              Swal.fire({
                                title: 'Alterações salvas!',
                                icon: 'success',
                                confirmButtonText: 'OK'
                              }).then(() => {
                                this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                              });
                            }, err => {
                              Swal.fire({
                                title: 'Ops!',
                                text: 'Não foi possível desativar o Organograma',
                                icon: 'error',
                                confirmButtonText: 'OK'
                              }).then(() => {
                                this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                              });
                            },
                          );
                      }
                    });

                    err => {
                      console.error(err);
                      Swal.fire({
                        html: 'Prezado, para inativar um organograma deve ser realizada a transferência de responsabilidade para outro organograma. No entanto, é necessário que exista ao menos um organograma ativo para efetuar a operação. Por favor, insira um novo organograma na plataforma!',
                        icon: 'info',
                        confirmButtonText: 'OK',
                      }).then(res => {
                        this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                      })
                    }

                  });
                }
              });
            }
            else {
              this.organogramaService.desativarOrganograma(this.codigoOrganograma, {})
                .subscribe(
                  res => {
                    Swal.fire({
                      title: 'Alterações salvas!',
                      icon: 'success',
                      confirmButtonText: 'OK'
                    }).then(() => {
                      this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
                    });
                  }, err => {
                    Swal.fire({
                      title: 'Ops!',
                      text: 'Não foi possível desativar o Organograma',
                      icon: 'error',
                      confirmButtonText: 'OK'
                    });
                  })
            }
          }, err => {
            console.error(err);
            Swal.fire({
              text: 'Prezado, para inativar um organograma deve ser realizada a transferência de responsabilidade para outro organograma. No entanto, é necessário que exista ao menos um organograma ativo para efetuar a operação. Por favor, insira um novo organograma na plataforma!',
              icon: 'info',
              confirmButtonText: 'OK'
            }).then(() => {
              this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
            });
          }
          );
        };
      }
    }
  }
}
