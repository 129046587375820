import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class AmbienteService {

  url: string = environment.BASE_URL;

  private clientes = new Subject<any>();
  private assinaturasDigitais = new Subject<any>();

  assinaturasDigitais$ = this.assinaturasDigitais.asObservable();

  constructor(private http: HttpClient) { }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  obterClientes(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/clientes`, { params }).pipe( map(data => {
      const body = data;
      this.clientes.next(body);
      return body;
    }));
  }

  obterDadosAmbiente(codigoCliente: number): Observable<any> {
    return this.http.get(`${this.url}/clientes/${codigoCliente}`)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }
  
  verificarAdesaoGovBr(): Observable<any> {
    return this.http.get(`${this.url}/clientes/adesaoGovBr`)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  atualizarDadosAmbiente(codigoCliente: number, cliente: any): Observable<any> {
    return this.http.put(`${this.url}/clientes/${codigoCliente}`, cliente)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  definirLogo(file: any): Observable<any> {
    const fd = new FormData();
    fd.append('imagem', file);
    return this.http.post(`${this.url}/clientes/logo`, fd, {
      headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
    })
  }

  obterImagem(): Observable<any> {
    return this.http.get(`${this.url}/clientes/logoBase64`);
  }

  listarAssinaturasEletronicas(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/regraAssinatura/listar`, {params: params})
      .map(data => {
        const body = data;
        this.assinaturasDigitais.next(body);
        return body;
      });
  }

}
