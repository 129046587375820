import { CommonModule  } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SharedModule } from '../../shared.module';
import { OpcoesLoginComponent } from './opcoes-login/opcoes-login.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { ResetarSenhaComponent } from './resetar-senha/resetar-senha.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { ClientesComponent } from './cliente/cliente.component';
import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { ECnpjComponent } from './e-cnpj/e-cnpj.component';

import { LoginRoutingModule } from './login.routing.module';
import { NgxMaskModule } from 'ngx-mask';
import { IconsValidacaoComponent } from './resetar-senha/componentes/icons-validacao/icons-validacao.component';

@NgModule({
  imports: [
    LoginRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  exports: [],
  declarations: [
    CertificadoComponent,
    OpcoesLoginComponent,
    RecuperarSenhaComponent,
    ResetarSenhaComponent,
    ConfirmacaoComponent,
    ClientesComponent,
    ProcuracaoComponent,
    ECnpjComponent,
    IconsValidacaoComponent
  ],
  providers: [],
})
export class LoginModule { }
