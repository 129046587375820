import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { environment } from "src/environments/environment";
import { tap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrganogramaService {

    url: string = environment.BASE_URL;

    private organograma = new Subject<any>();
    organograma$ = this.organograma.asObservable();


    constructor(private http: HttpClient) {

    }

    getOrganogramas(searchParams) {
        const params = searchParams
        return this.http.get(`${this.url}/organograma/listarOrganogramas`, { params: params })
            .map(data => {
                const body = data;
                this.organograma.next(body);
                return body;
            });
    }

    addOrganograma(obj) {
        return this.http.post(`${this.url}/organograma/incluirOrganograma`, obj);
    }

    getOrganogramaData(id: number) {
        return this.http.get(`${this.url}/organograma/listarOrganograma/${id}`);
    }

    editOrganograma(obj, id: number) {
        return this.http.post(`${this.url}/organograma/editarOrganograma/${id}?ativo=true`, obj);
    }

    desativarOrganograma(idAntigo, objetoOrganograma) {
        return this.http
            .post(
                `${this.url}/organograma/desativarOrganograma/${idAntigo}`,
                objetoOrganograma
            )
            .pipe(map(data => {
                const body = data['resultado'];
                return body;
            }));
    }

    getTodosOrganogramas(codUsuario) {
        return this.http.get(`${this.url}/organograma/listarOrganogramasUsuario/${codUsuario}`);
    }

    getOrganogramasEmUso(id) {
        return this.http.get(`${this.url}/organograma/emUso/${id}`);
    }

    getOrganogramasPortabilidade(seqOrganograma) {
        return this.http.get(`${this.url}/organograma/listarOrganogramasPortabilidade/${seqOrganograma}`);
    }

    listaOrganogramaComunicados() {
        return this.http.get(`${this.url}/organograma/listarOrganogramasComunicados`);
    }

    listaOrganogramaComunicado(codComunicado) {
        return this.http.get(`${this.url}/organograma/listarOrganogramaComunicado/${codComunicado}`);
    }
}
