import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

import { Router } from '@angular/router';

@Component({
  selector: "app-formulario-adm-auditor",
  templateUrl: "formulario-adm-auditor.component.html",
  styleUrls: ["formulario-adm-auditor.component.scss"],
})
export class FormularioAdministradorAuditor implements OnInit {
  admAuditorForm: FormGroup;
  loading: boolean = true;
  perfisAcesso: Array<any> = [];
  perfilAcesso: Object = null;
  title: string = "Incluir: Auditor | Administrador | Administrativo | Encarregado";

  //situacaoUsuario: any;

  constructor(
    private fb: FormBuilder,
    private gerenciarUsuarioService: GerenciarUsuariosService,
    private router: Router
  ) { }

  ngOnInit() {
    this.obterPerfisAcesso();
    this.criarFormulario();
  }

  obterPerfisAcesso() {
    this.gerenciarUsuarioService.obterPerfisAcesso().subscribe((res) => {
      this.loading = false;
      this.perfisAcesso = res.filter((perfil) => perfil.codigo === 0 ||
        perfil.codigo === 1 ||
        perfil.codigo === 4 ||
        perfil.codigo === 5);
    });
  }

  criarFormulario() {
    this.admAuditorForm = this.fb.group({
      cpf: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ]),
      ],
      nome: [
        "",
        Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
      ],
      celular: ["", Validators.compose([Validators.required])],
      email: ["", Validators.required],
      perfil: ["", Validators.required],

      situacaoUsuario: [0, Validators.required],
    });
  }

  selectPerfil(e: any) {
    const codigo = parseInt(e, 10);
    this.perfilAcesso = this.perfisAcesso.filter(
      (elem) => elem.codigo === codigo
    )[0];
  }

  ajuda() {
    Swal.fire({
      html: "Se estiver em Sim, o usuário, assim como os comunicados trocados por este, não irão ser contabilizados em relatórios e consultas.",
      confirmButtonText: "Fechar",
    });
  }

  consultarUsuario(cpf) {
    if (this.admAuditorForm.controls.cpf.valid) {
      this.gerenciarUsuarioService.getApiExterna(cpf).subscribe(
        (res) => {
        },
        (error) => {
          if (error.error.excecao.recomendacao != "") {
            Swal.fire(
              getSwal("error", "", error.error.excecao.recomendacao, "", "")
            ).then(res => {
              this.admAuditorForm.controls.cpf.setErrors({ invalido: true })
            });
          } else {
            this.admAuditorForm.controls.cpf.setErrors(null)
          }

        }
      );
    }
  }

  validarNome() {
    if (this.admAuditorForm.controls.nome.value.trim().length == 0) {
      this.admAuditorForm.controls.nome.setValue('');
    }
  }

  adicionarUsuario() {
    this.loading = true;
    const obj = this.admAuditorForm.getRawValue();
    obj["ddd"] = obj["celular"].substring(0, 2);
    obj["celular"] = obj["celular"].substr(2, 9);
    obj["perfilAcesso"] = this.perfilAcesso;
    this.gerenciarUsuarioService.adicionarAdmAuditor(obj).subscribe(
      (res) => {
        this.loading = false;
        // Swal.fire('Sucesso!', 'Usuário criado com êxito', 'success')
        Swal.fire(
          getSwal("success", "Sucesso!", "Usuário criado com êxito", "ok", null)
        ).then((res) => {
          this.router.navigate(["gestao-usuario/consultar"]);
        });
      },
      (err) => {
        this.loading = false;
        Swal.fire(
          getSwal("error", "Ops!", err.error.excecao.recomendacao, "ok", null)
        );
      }
    );
  }
}
