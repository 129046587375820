import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { TipoAprovacaoUsuarioService } from '../../../../../service/tipo-aprovacao-usuario/tipo-aprovacao-usuario.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from '../../../../../../../node_modules/rxjs';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { HeaderService } from 'src/app/service/header/header.service';


@Component({
  selector: 'app-editar-ambiente',
  templateUrl: 'editar-ambiente.component.html',
  styleUrls: ['./editar-ambiente.component.scss']
})
export class EditarAmbienteComponent implements OnInit, AfterViewInit {
  routeSub: Subscription;
  editarAmbienteForm: FormGroup;
  idPerfilAcesso: number = null;
  tiposUsuarios = [];
  dadosDeAmbiente: any = {};
  hasLogo: boolean = false;
  logo: SafeUrl;
  logoDte: SafeUrl;
  tipoUsuario: any = {
    codigo: -1,
    descricao: 'Selecione'
  };
  // tituloDte:'';
  image: any;
  imageLogoDte: any;
  loading: boolean = false;

  autoCadastro: number = null;
  habilitaComboAutoCadastro : boolean = false;
  exigeCertificadoCnpj: boolean = false;
  
  exigeNumIBGE: any;
  numIbge: string;

  customClass: {
  container: 'container-class',
  popup: 'popup-class',
  header: 'header-class',
  title: 'title-class',
  closeButton: 'close-button-class',
  icon: 'icon-class',
  image: 'image-class',
  content: 'content-class',
  input: 'input-class',
  actions: 'actions-class',
  confirmButton: 'confirm-button-class',
  cancelButton: 'cancel-button-class',
  footer: 'footer-class'
  }

  labels: any;



  constructor(
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private ambienteService: AmbienteService,
    private tipoAprovacaoUsuario: TipoAprovacaoUsuarioService,
    private translate: TranslateService,
    private headerService: HeaderService
  ) {
    this.setLabels();
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }

  ngOnInit() {
    this.verificarCodigoIBGE();
    this.criarFormulario();
    this.tipoAprovacaoUsuario.obterTiposAutorizacao().subscribe(res => {
      this.tiposUsuarios = [
        {
          codigo: -1,
          descricao: 'Selecione'
        }
      ];
      this.tiposUsuarios = this.tiposUsuarios.concat(res.resultado);

    });
  }

  ngAfterViewInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
    // this.obterImagem();
  }

  obterImagem(){
    this.ambienteService.obterImagem().subscribe(
      res => {
        this.logo = 'data:image/jpeg;base64,' + res.resultado;
      }, err => {
        console.log(err.error.text);
      });
  }

  criarFormulario() {
    this.editarAmbienteForm = this.fb.group({
      razaoSocial:  ['', [Validators.required, Validators.minLength(10), Validators.maxLength(60)]],
      ativo: [false, Validators.required],
      inicioContrato: '',
      fimContrato: '',
      inicioContratoFormatado: '',
      fimContratoFormatado: '',
      numIbge: null,
      configuracao: this.fb.group({
        tipoAprovacaoUsuario: [
          {
            codigo: -1,
            descricao: 'Selecione'
          }
        ],
        url: '',
        apiAlfresco: [''],
        urlAuditor: [''],
        urlContribuinte: [''],
        usuarioEscrita: [''],
        senhaEscrita: [''],
        pastaAlfresco: [''],
        hostSmtp: [''],
        portaSmtp: [''],
        usuarioSmtp: [''],
        senhaSMTP: [''],
        termoAdesao: [''],
        endpointSms: [''],
        contaSms: [''],
        codigoSms: [''],
        alertaProcuracao: [''],
        prazoProcuracao: [0],
        autoCadastro: null,
        exigeCertificadoCnpj: null,
        exigeTermoAdesao: false,
        exigeAdesaoGov: false,
        exigeTipoComunicao: [''],
        tituloDte: ['', [Validators.required]],
        logo:'',
        logoDte:''
      })
    });
  }
  
  verificarCodigoIBGE() {
    this.ambienteService.verificarAdesaoGovBr().subscribe(
      res => {
        this.exigeNumIBGE = res.resultado;
        console.log(res.resultado)
      }
    )
  }

  onSelectFile(img) {
    if (img.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(img.target.files[0]);
      reader.onload = (event: any) => {
      };

      this.image = img.target.files[0];

      const fileReader = new FileReader();
      const file = img.target.files[0];
      const currentScope = this;
      fileReader.readAsDataURL(file);
      fileReader.onload = function(e: any) {
        currentScope.logo = e.target.result;
      };
      // this.onUpload();
    }
  }


  onSelectFileDte(img) {
    if (img.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(img.target.files[0]);
      reader.onload = (event: any) => {
      };

      this.imageLogoDte = img.target.files[0];

      const fileReader = new FileReader();
      const file = img.target.files[0];
      const currentScope = this;
      fileReader.readAsDataURL(file);
      fileReader.onload = function(e: any) {
        currentScope.logoDte = e.target.result;
      };
    }
  }

  onUpload() {
    this.loading = true;
    this.ambienteService.definirLogo(this.image).subscribe(
      res => {
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.logo = null;
        Swal.fire(
          getSwal('error', 'Ops!', err.error.excecao.recomendacao, 'ok', null)
        );
      });
  }

  obterDados() {
    this.ambienteService.obterDadosAmbiente(this.idPerfilAcesso).subscribe(
      res => {
        //USE TO TEST: res['resultado'].fimContrato = "";
        res.resultado.fimContratoFormatado =
          res.resultado.fimContrato && res.resultado.fimContrato !== null
            ? moment(res.resultado.fimContrato).format('DD/MM/YYYY')
            : '';
        if (
          res.resultado.fimContratoFormatado.toLowerCase() == 'invalid date'
        ) {
          res.resultado.fimContratoFormatado = ' ';
        }

        if(res.resultado.configuracao.logoDte){
        this.logoDte = 'data:image/jpeg;base64,' + res.resultado.configuracao.logoDte;
        }

        if(res.resultado.configuracao.logo){
          this.logo = 'data:image/jpeg;base64,' + res.resultado.configuracao.logo;
          }
        res.resultado.inicioContratoFormatado =
          res.resultado.inicioContrato && res.resultado.inicioContrato !== null
            ? moment(res.resultado.inicioContrato).format('DD/MM/YYYY')
            : '';

        this.dadosDeAmbiente = res.resultado;
        this.carregarDadosFormulario(this.dadosDeAmbiente);
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }

  carregarDadosFormulario(data) {
    for (let key in data) {
      if (this.editarAmbienteForm.controls[key] && data[key] !== null) {
        this.editarAmbienteForm.controls[key].patchValue(data[key]);
      }
    }

    if (data.configuracao !== null) {
      this.tipoUsuario = data.configuracao.tipoAprovacaoUsuario;
      this.editarAmbienteForm
        .get('configuracao')
      ['controls'].tipoAprovacaoUsuario.patchValue(this.tipoUsuario);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].senhaSMTP.setValue(data.configuracao.senhaSmtp);
      this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].urlContribuinte.setValue(data.configuracao.urlContribuinte);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].autoCadastro.setValue(data.configuracao.autoCadastro);

      this.editarAmbienteForm.controls.configuracao[
        'controls'
        ].exigeCertificadoCnpj.setValue(data.configuracao.exigeCertificadoCnpj);

      this.autoCadastro = data.configuracao.autoCadastro;

      this.exigeCertificadoCnpj = data.configuracao.exigeCertificadoCnpj

      /**this.editarAmbienteForm.controls.configuracao[
        'controls'
      ].exigirOrtoga.setValue(data.configuracao.prazoProcuracao);**/

      // if (data.configuracao.logo !== null) {
      //   // this.hasLogo = true;
      //   this.logo = this.domSanitizer.bypassSecurityTrustUrl(
      //     'data:image/jpeg;base64,' + data.configuracao.logo
      //   );
      // } else {
      //   // this.hasLogo = false;
      //   this.logo = null;
      // }

    } else {
      this.tipoUsuario = 0;
    }
  }

  disableFields() {
    this.editarAmbienteForm.controls.razaoSocial.disable();
    this.editarAmbienteForm.controls.inicioContrato.disable();
    this.editarAmbienteForm.controls.fimContrato.disable();
    this.editarAmbienteForm.controls.configuracao.get('url').disable();
  }

  atualizar() {
    const obj = this.checkChanges();
    console.log(obj);


    this.ambienteService
      .atualizarDadosAmbiente(this.dadosDeAmbiente.codigo, obj)
      .subscribe(
        res => {
          Swal.fire(
            getSwal(
              'success',
              'Sucesso',
              'Ambiente atualizado com sucesso.',
              'ok',
              null
            )
          ).then(res => {
            this.router.navigate(['configuracao/ambiente']);
          });
          this.headerService.updateLogo();
        },
        error => {
          console.log(error);
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          );
        }
      );
  }

  checkChanges() {
    let value = this.editarAmbienteForm.getRawValue();
    value.numIbge = value.numIbge === "" ? null : value.numIbge;
    const updatedValue = {};
    for (let key in value) {
      if (
        value[key] !== this.dadosDeAmbiente[key] &&
        value[key] !== 'configuracao'
      ) {
        this.dadosDeAmbiente[key] = value[key];
      }
    }

    for (let key in value.configuracao) {
      if (
        value.configuracao[key] !== this.dadosDeAmbiente.configuracao[key] &&
        value[key] !== 'tipoAprovacaoUsuario'
      ) {
        this.dadosDeAmbiente.configuracao[key] = value.configuracao[key];
      }

      if (
        value.configuracao[key] &&
        value.configuracao[key] !== null &&
        value.configuracao[key].toString().length === 0
      ) {
        delete this.dadosDeAmbiente.configuracao[key];
      }
    }

    var regraUso = {};


    if(this.dadosDeAmbiente.regraUso){
      regraUso = {
        tipoComunicado: {codigo: this.dadosDeAmbiente.regraUso.tipoComunicado.codigo}
      }
      this.dadosDeAmbiente.regraUso = regraUso;
    } else {
      this.dadosDeAmbiente.regraUso = null;
    }

    this.dadosDeAmbiente.inicioContrato = this.dadosDeAmbiente.inicioContratoFormatado;
    this.dadosDeAmbiente.inicioContrato = moment(this.dadosDeAmbiente.inicioContrato, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');
    this.dadosDeAmbiente.fimContrato = this.dadosDeAmbiente.fimContratoFormatado;
    this.dadosDeAmbiente.fimContrato = moment(this.dadosDeAmbiente.fimContrato, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');
    delete this.dadosDeAmbiente.inicioContratoFormatado;
    delete this.dadosDeAmbiente.fimContratoFormatado;

    var base64 = String(this.logoDte).split(",")
    console.log('base64:', base64)
    this.dadosDeAmbiente.configuracao.logoDte = base64[1]

    var logoBase64 = String(this.logo).split(",")
    console.log('base64:', logoBase64)
    this.dadosDeAmbiente.configuracao.logo = logoBase64[1]


    return this.dadosDeAmbiente;
  }

  limparTipoUsuario(event) {
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  alteracaoTipoUsuario(codigo) {
    if (typeof codigo !== 'object') {
      return;
    }

    if (typeof codigo === 'object') {
      if(codigo.codigo === 0 ){
        this.habilitaComboAutoCadastro = true;
      }else{
        this.habilitaComboAutoCadastro = false;
      }
    }

    this.tipoUsuario = codigo;
  }

  alteracaoAutoCadastro(codigo) {
    this.autoCadastro = codigo;
  }

  alteracaoExigeCertificadoCnpj(codigo) {
    this.exigeCertificadoCnpj = codigo;
  }

  setLabels() {
    this.labels = {
      urlPortal: this.translate.instant('CONFIGURACOES.AMBIENTES.URLPORTAL'),
      urlAuditor: this.translate.instant('CONFIGURACOES.AMBIENTES.URLAUDITOR'),
      razaoSocial: this.translate.instant('GLOBAIS.RAZAOSICIAL'),
      nomePortal: this.translate.instant('GLOBAIS.NOMEPORTAL'),
      situacao: this.translate.instant('GLOBAIS.SITUACAO'),
      inativo: this.translate.instant('GLOBAIS.INATIVO'),
      ativo: this.translate.instant('GLOBAIS.ATIVO'),
      dataContrato: this.translate.instant('GLOBAIS.DATACONTRATO'),
      dataFim: this.translate.instant('GLOBAIS.DATAFIM'),
      logotipo: this.translate.instant('CONFIGURACOES.AMBIENTES.LOGOTIPO'),
      logotipoplataforma: this.translate.instant('CONFIGURACOES.AMBIENTES.LOGOTIPOPLATAFORMA'),
      codigoAplicacao: this.translate.instant('CONFIGURACOES.AMBIENTES.CODIGOAPLICACAO'),
      formaAtivacaoUsuario: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.FORMAATIVACAOUSUARIO'
      ),
      usuarioEscritaAlfresco: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.USUARIOESCRITAALFRESCO'
      ),
      usuarioSenhaAlfresco: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.USUARIOSENHAALFRESCO'
      ),
      urlAlfresco: this.translate.instant('CONFIGURACOES.AMBIENTES.URLALFRESCO'),
      pastaAlfresco: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.PASTAALFRESCO'
      ),
      servidorSmtp:
        this.translate.instant('CONFIGURACOES.AMBIENTES.SERVIDOR') +
        ' ' +
        this.translate.instant('CONFIGURACOES.AMBIENTES.SMTP'),
      portaSmtp:
        this.translate.instant('CONFIGURACOES.AMBIENTES.PORTA') +
        ' ' +
        this.translate.instant('CONFIGURACOES.AMBIENTES.SMTP'),
      usuarioSmtp:
        this.translate.instant('GLOBAIS.USUARIO') +
        ' ' +
        this.translate.instant('CONFIGURACOES.AMBIENTES.SMTP'),
      senhaSmtp:
        this.translate.instant('GLOBAIS.SENHA') +
        ' ' +
        this.translate.instant('CONFIGURACOES.AMBIENTES.SMTP'),
      endpointServicoSms: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.ENDPOINTSERVICOSMS'
      ),
      contaServicoSms: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.CONTASERVICOSMS'
      ),
      codigoServicoSms: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.CODIGOSERVICOSMS'
      ),
      alertaProcuracao: this.translate.instant(
        'CONFIGURACOES.AMBIENTES.ALERTAPROCURACAO'
      ),
      exigirOrtoga: this.translate.instant('CONFIGURACOES.AMBIENTES.EXIGIRORTOGA')
    };
  }

  ajuda(valor) {
    let msg1=
    ` <ol style="margin-right: 40px; text-align: center;">
    <li> Nome da plataforma.
    </li>
  </ol>`;

  let msg2 = ` <ol style="margin-right: 40px; text-align: center;">
    <li> Imagem que será apresentada no cabeçalho de documentos enviados.</li>
  </ol>`;

    let msg3 = ` <ol style="margin-right: 40px; text-align: center;">
    <li>  Permite desativar o acesso ao Portal DT-e e Portal DT-e Gestor para perfis de Contribuinte e Auditor.
    </li>
    <li> Na situação Inativo, quando um usuário tentar acessar um dos portais, é avisado via alerta que o portal está em manutenção;
    nesta situação, somente o Administrador de Plataforma consegue acessar o Portal DT-e Gestor e alterara a situação.</li>
  </ol>`;

    let msg4= ` <ol style="margin-right: 40px; text-align: center;">
    <li>  Data que iniciou o uso do Portal DT-e, indicando que os dados movimentados tem esta data como partida.</li>
  </ol>`;

    let msg5 = ` <ol style="margin-right: 40px; text-align: center;">
    <li>  Data programada para inativar os portais automaticamente, alterando a <b>Situação</b> para Inativo quando chega na data.</li>
  </ol>`;

    let msg6= ` <ol style="margin-right: 40px; text-align: center;">
    <li>  Define o procedimento que plataforma adota para cadastrar os usuários com perfil de acesso do tipo Contribuinte.</li>
    <li class="my-2"> As formas de cadastro aceitos são: </li>
    <li class="my-2"><b>Auto Cadastro</b></li>
    <li class="my-2"> A plataforma aplica regra de dados mínimos informados antes de ativar a conta do usuário,
    e faz uso de assinatura eletrônica para ativar o usuário.</li>


    <li class="my-2"><b>Aprovação automática</b></li>
    <li class="my-2">Os usuários são importados para a plataforma via carga de dados, e são ativados por ofício.</li>

    <li class="my-2"> <b>Aprovação manual</b></li>
    <li class="my-2">A plataforma não ativa os usuários, inseridos via auto cadastro ou importados via Integração,
    sem a intervenção via Gerenciar Usuário, de um Auditor Fiscal.<li>

    <li class="my-2"> <b>Aprovação presencial</b></li>
        <li class="my-2">A plataforma não ativa os usuários, mas apresenta uma agenda para atendimento presencial na
        instituição, onde deve ser apresentado documentos para análise e ativação pelo Auditor Fiscal</li>
  </ol>`;
    let msg7= ` <ol style="margin-right: 40px; text-align: center;">
    <li> Informa o endereço oficial de acesso a plataforma para o Contribuinte,
    que é utilizado nas mensagens de e-mail para orientar o usuário quando tiver comunicado disponível.</li>
  </ol>`;

    let msg8= ` <ol style="margin-right: 40px; text-align: center;">
    <li class="my-2"> Informa o endereço oficial de acesso a plataforma para o Auditor, que é utilizado nas mensagens de e-mail para orientar o usuário
    quando tiver comunicado disponível.</li>
  </ol>`;
    let msg9= ` <ol style="margin-right: 40px; text-align: center;">
    <li class="my-2"> Endereço do serviço (API) que retorna os dados de Contribuinte para ser apresentado na funcionalidade Perfil de Contribuinte.</li>
  </ol>`;
    let msg10 = ` <ol style="margin-right: 40px; text-align: center;">
    <li> Usuário com direitos de leitura e escrita no Alfresco </li>
  </ol>`;
    let msg11 = `<ol style="margin-right: 40px; text-align: center;">
    <li>Senha do usuário de leitura e escrita no Alfresco</li>
  </ol>`;
    let msg12 = `<ol style="margin-right: 40px; text-align: center;">
    <li>Endereço da API do Alfresco </li>
  </ol>`;
    let msg13= `<ol style="margin-right: 40px; text-align: center;">
    <li>
    Pasta do usuário do Alfresco que será raiz para criar os diretórios </li>
  </ol>`;
    let msg14= `<ol style="margin-right: 40px; text-align: center;">
    <li> Endereço do servidor que oferece o serviço de e-mail</li>
  </ol>`;
    let msg15 = `<ol style="margin-right: 40px; text-align: center;">
    <li>Porta de conexão para o serviço de e-mail</li>
  </ol>`;
    let msg16 = `<ol style="margin-right: 40px; text-align: center;">
    <li> Senha do usuário para acesso ao serviço </li>
  </ol>`;
    let msg17 = `<ol style="margin-right: 40px; text-align: center;">
    <li>Usuário para acesso ao serviço</li>
  </ol>`;
    let msg18 = `<ol style="margin-right: 40px; text-align: center;">
    <li> Senha do usuário para acesso ao serviço
    </li>
  </ol>`;
    let msg19 = `<ol style="margin-right: 40px; text-align: center;">
      <li>Endereço do servidor/serviço de SMS
      </li>
  </ol>`;
    let msg20 = `<ol style="margin-right: 40px; text-align: center;">
      <li>Usuário de conexo ao serviço
      </li>
  </ol>`;
    let msg21 = `<ol style="margin-right: 40px; text-align: center;">
    <li>Código de ativação do serviço</li>
  </ol>`;

    let msg22= `<ol style="margin-right: 40px; text-align: center;">
    <li>Indica, em dias, se deve ser alertado ao Procurador quando está para vencer uma procuração</li>
  </ol>`;

    let msg23= `<ol style="margin-right: 40px; text-align: center;">
    <li>Indica, em dias, se há prazo para aceitar uma procuração</li>
    <li>Se 0 (zero), procuração outorgada automaticamente fica válida</li>
  </ol>`;

    let msg24= `<ol style="margin-right: 40px; text-align: center;">
    <li>Indica se no processo de auto-cadastro de Contribuinte  ou Procurador do tipo Pessoa Jurídica devem utilizar o
    Certificado Digital para habilitar o cadastro no portal do domicílio</li>
  </ol>`;

    let msg25= `<ol style="margin-right: 40px; text-align: center;">
    <li>Define se pessoa jurídica pode acessar por CNPJ e senha e certificado digital, ou somente via certificado digital padrão ICP-Brasil.</li>
  </ol>`;

  let msg26= `<ol style="margin-right: 40px; text-align: center;">
    <li>Título que será apresentado aos usuários como sendo o nome da plataforma.</li>
  </ol>`;

  let msg27= `<ol style="margin-right: 40px; text-align: center;">
    <li>Imagem que será apresentada para identificar o produto nas plataformas.</li>
  </ol>`;

  let msg28= `<ol style="margin-right: 40px; text-align: center;">
    <li>O código IBGE é aplicado durante a adesão de contribuinte ao domicílio para garantir que somente residentes ou empresas
    locais sejam as usuárias da plataforma.</li>
  </ol>`;

  let msg29= `<ol style="margin-right: 40px; text-align: center;">
    <li>Informe a chave de integração (API Key) gerado no PRUC para identificação segura no consumo das APIs do DT-e
  </ol>`;


    switch(valor) {
      case 'razaosocial': {
        Swal.fire({
          html:msg26,
          confirmButtonText:'OK'
        })
         break;
      }
      case 'logotipo': {
        Swal.fire({
          html:msg2,
          confirmButtonText:'OK' 
        })
         //statements;
         break;
      }
      case 'situacao': {
        Swal.fire({
          html:msg3,
          confirmButtonText:'Ok'
        })
         //statements;
         break;
      }
      case 'datacontrato': {
        Swal.fire({
          html:msg4,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'datafim': {
        Swal.fire({
          html:msg5,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'formaativacaousuario': {  // reever Opção adotadota
        Swal.fire({
          html:msg6,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'urlportal': {
        Swal.fire({
          html:msg7,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'urlauditor': {
        Swal.fire({
          html:msg8,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'urlservicointegracao': {
        Swal.fire({
          html:msg9,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'ambientealfresco': {
        Swal.fire({
          html:msg10,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'usuariosenhaalfresco': {
        Swal.fire({
          html:msg11,
          confirmButtonText:'Ok'

        })
         break;
      }
      case 'urlalfresco': {
        Swal.fire({
          html:msg12,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'pastaalfresco': {
        Swal.fire({
          html:msg13,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'servidorsmtp': {
        Swal.fire({
          html:msg14,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'portasmtp': {
        Swal.fire({
          html:msg15,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'usuariosmtp': {
        Swal.fire({
          html:msg17,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'senhasmtp': {
        Swal.fire({
          html:msg18,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'endpointservicosms': {
        Swal.fire({
          html:msg19,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'contaservicosms': {
        Swal.fire({
          html:msg20,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'codigoservicosms': {
        Swal.fire({
          html:msg21,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'alertaprocuracao': {
        Swal.fire({
          html:msg22,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'exigirortoga': {
        Swal.fire({
          html:msg23,
          confirmButtonText:'Ok'
        })
         break;
      }
      case 'autocadastro': {
        Swal.fire({
          html:msg24,
          confirmButtonText:'Ok'
        })
        break;
      }
      case 'exigeCertificadoCnpj': {
        Swal.fire({
          html:msg25,
          confirmButtonText:'Ok'
        })
        break;
      }
      case 'nomePortal': {
        Swal.fire({
          html:msg1,
          confirmButtonText:'Ok'
        })
        break;
      }
      case 'logotipoPlataforma': {
        Swal.fire({
          html:msg27,
          confirmButtonText:'Ok'
        })
         //statements;
         break;
      }
      case 'codigoIBGE': {
        Swal.fire({
          html:msg28,
          confirmButtonText:'Ok'
        })
         //statements;
         break;
      }
        
      default: {
         //statements;
         break;
      }
   }
  }
}
