import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  animate,
  style,
  keyframes
} from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LoginService } from '../../../service/login/login.service';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';
import { HeaderService } from 'src/app/service/header/header.service';

@Component({
  selector: '[app-login]',
  templateUrl: 'opcoes-login.component.html',
  styleUrls: ['./opcoes-login.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ]),
    trigger('shake', [
      transition(
        ':enter',
        animate(
          400,
          keyframes([
            style({ transform: 'translate(30px)', offset: 0.0 }),
            style({ transform: 'translate(-30px)', offset: 0.2 }),
            style({ transform: 'translate(15px)', offset: 0.4 }),
            style({ transform: 'translate(-15px)', offset: 0.6 }),
            style({ transform: 'translate(8px)', offset: 0.8 }),
            style({ transform: 'translate(0px)', offset: 1 })
          ])
        )
      )
    ])
  ]
})
export class OpcoesLoginComponent implements OnInit {
  cpf_cnpj = '';
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';
  pureResult: any;
  maskedId: any;
  val: any;
  v: any;
  loading = false;
  loginForm: FormGroup;
  errorMsg: string = null;
  hasError = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private sessionService: SessionService,
    private headerService: HeaderService
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    if (this.sessionService.getToken()) {
      this.router.navigate(['/home']);
    }
    if (window.localStorage.getItem('sentToSignIn') !== null) {
      localStorage.removeItem('sentToSignIn');
      location.reload();
    }
  }

  criarFormulario() {
    this.loginForm = this.fb.group({
      cpfCnpj: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)])
      ],
      senha: ['', Validators.required]
    });
  }

  onSubmit(submitBtn: HTMLButtonElement) {
    submitBtn.disabled = true;
    this.hasError = false;
    this.loading = true;
    this.loginService
      .login(this.loginForm.value)
      .then(res => {
        this.handleResponse(res);
      })
      .catch(err => {
        this.handleError(err);
      });
    //this.loginForm.reset();
  }

  handleResponse(res) {
    this.loading = false;
    this.loginForm.reset();
    const userInfo = res.resultado;
    this.userService.sendInfo({ loggedIn: false, ...res.resultado });
    this.loginConfigs(res.resultado);
  }

  loginConfigs(obj) {
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      res => {
        let response = res.resultado;
        if (response.length <= 1) {
          response = res.resultado[0];
          this.loginService.obterSessao(response.codigo).then(
            data => {
              if (data['resultado'].perfilAcesso.codigo == 5) {
                this.userService
                  .sendInfo({
                    loggedIn: true,
                    firstVisit: true,
                    ...data['resultado']
                  })
                  .then(res => {
                    this.router.navigate(['/caixa-postal/mensagens']);
                  });
                return;
              }
              if (data['resultado'].perfilAcesso.codigo !== 3) {
                this.userService
                  .sendInfo({
                    loggedIn: true,
                    firstVisit: true,
                    ...data['resultado']
                  })
                  .then(res => {
                    this.router.navigate(['/home']);
                  });
              } else {
                this.userService
                  .sendInfo({
                    loggedIn: false,
                    ...data['resultado']
                  })
                  .then(res => {
                    this.router.navigate(['/procuracao']);
                  });
              }
            },
            error => this.handleError(error)
          );
        } else {
          this.router.navigate(['clientes']);
        }
      },
      error => {
        if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
          Swal.fire(
            getSwal(
              'warning',
              '',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          )
        } else {
          Swal.fire(
            '',
            '',
            error.error.excecao.codigo === 'E000' ? 'warning' : 'error'
          ).then(() => {
            this.userService.sendInfo({
              loggedIn: false
            });
            this.sessionService.clearSession();
            this.router.navigate(['']);
          });
        }
      }
    );
  }

  handleError(err) {
    const error = err['error'];
    // if(error.excecao.recomendacao){
    //   this.errorMsg = error.excecao.recomendacao;
    // }else{
    //   this.errorMsg = "Favor verificar seus dados de acesso."
    // }
    this.errorMsg = 'Favor verificar seus dados de acesso.';
    this.loading = false;
    this.hasError = true;
    //this.cpf_cnpj = '';
    //this.loginForm.reset();
    this.loginForm.patchValue({
      cpfCnpj: this.cpf_cnpj,
      senha: ''
    });
    this.userService.sendInfo({
      loggedIn: false
    });
    this.sessionService.clearSession();
  }
}
