import { ProcuracoesService } from './../../../../../service/procuracoes/procuracoes.service';
import { GerenciarUsuariosService } from './../../../../../service/configuracao/gerenciar-usuarios.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import { SessionService } from '../../../../../service/session/session.service';

import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import * as moment from 'moment'
import { DomSanitizer } from '@angular/platform-browser';
import { CpfCnpjPipe } from 'src/app/pipes/cpf-cnpj.pipe';

@Component({
  selector: '[app-message]',
  templateUrl: 'message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageDetailsComponent implements OnInit {
  subscription: Subscription;
  reply = false;
  mensagem: any = null;
  codigo: number = null;
  mensagens: Array<object> = [];
  index: number = null;
  alreadyReplying = false;
  messagesList: Array<any> = [];

  loading = false;

  dataAtual: any = new Date();
  dataBloqueada: any;
  dataFormatada: any;
  cpfRepresentanteLegal: any;
  nomeRazaoSocialRepresentanteLegal: any;
  cpfCnpjContribuinte: any;
  nomeRazaoSocialContribuinte: any;
  cpfCnpjProcurador: any;
  nomeRazaoSocialProcurador: any;
  userLogado: String;
  bloquearResposta: boolean = false;
  quantidadeComunicados: number;
  filtroRespostasPaginacao: Object = {};

  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10,
  };
  protocoloResposta: any;

  constructor(
    private route: ActivatedRoute,
    private caixaPostalService: CaixaPostalService,
    private sessionService: SessionService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private gerenciarUsuariosService: GerenciarUsuariosService,
    private procuracoesService: ProcuracoesService,
    private pipeDoc: CpfCnpjPipe,
  ) {
    this.router.getCurrentNavigation();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state;
    this.filtroRespostasPaginacao = state.obj;
    this.protocoloResposta = state.protocoloResposta;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.codigo = parseInt(params.get('id'));
      this.consultarMensagens();
    });
  }

  consultarMensagens() {
    this.messagesList = [];
    this.loading = true;
    let session = this.sessionService.getSession();
    this.userLogado = session['usuario'].cpfCnpj;

    this.caixaPostalService.consultarMensagem(this.codigo).subscribe(res => {
      this.handleResponse(res)
      console.log('res', res)
    },
      error => console.log(error));
  }

  mensagemAnterior() {
    let index;
    let protocolo;

    this.router.navigate([`/caixa-postal/mensagem/${protocolo}`]).then(() => {
      this.protocoloResposta = protocolo;
    });

    if (this.index === 0) {
      this.index = 1
    };

    if (this.filtroRespostasPaginacao['respondidas']) {
      index = this.mensagens.findIndex(item => item['protocolo'] == this.protocoloResposta);
      index--;

      if (index < this.mensagens.length) {
        protocolo = this.mensagens[index]['protocolo'];
      }
    } else {
      index = this.mensagens.findIndex(item => item['protocolo'] == this.mensagem['protocolo']);
      index--;
      if (index < this.mensagens.length) {
        protocolo = this.mensagens[index]['protocolo'];
      }
    }

    this.router.navigate([`/caixa-postal/mensagem/${protocolo}`])
  }

  mensagemPosterior() {
    let index;
    let protocolo;

    this.router.navigate([`/caixa-postal/mensagem/${protocolo}`]).then(() => {
      this.protocoloResposta = protocolo;
    });

    if (this.index === 0) {
      this.index = 1
    };

    if (this.filtroRespostasPaginacao['respondidas']) {
      index = this.mensagens.findIndex(item => item['protocolo'] == this.protocoloResposta);
      index++;

      if (index < this.mensagens.length) {
        protocolo = this.mensagens[index]['protocolo'];
      }
    } else {
      index = this.mensagens.findIndex(item => item['protocolo'] == this.mensagem['protocolo']);
      index++;
      if (index < this.mensagens.length) {
        protocolo = this.mensagens[index]['protocolo'];
      }
    }

    this.router.navigate([`/caixa-postal/mensagem/${protocolo}`])
  }

  darCiencia() {
    let thisCienciaMensagem = this.mensagem['cienciaMensagem'];
    console.log(thisCienciaMensagem);
    if (thisCienciaMensagem.length == 0) {
      this.router.navigate([`/caixa-postal/certificados/${this.codigo}`]);
    } else {
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Não é possí­vel Dar Ciência para esta mensagem: ela já conta com ciência vigente',
          'ok',
          null
        )
      );
    }
  }

  nomearAdvogados() {
    this.router.navigate([`/caixa-postal/nomear-advogado/${this.codigo}`]);
  }

  arquivarMensagem() {
    this.loading = true;
    const protocolo = this.codigo;
    this.caixaPostalService.arquivarMensagem(protocolo).subscribe(
      res => {
        this.loading = false;
        // Swal.fire('Sucesso!','O comunicado foi arquivado com êxito.','success');
        Swal.fire(
          getSwal(
            'success',
            'Sucesso!',
            'O comunicado foi arquivado com êxito.',
            'ok',
            null
          )
        ).then(() => {
          console.log('res', res)
          this.router.navigate(['/caixa-postal/mensagens']);
        });
      },
      error => {
        // Swal.fire('Ops!','Ocorreu um erro e o comunicado não pôde ser arquivado.','error');
        Swal.fire(
          getSwal(
            'info',
            'Ops!',
            'Ocorreu um erro e o comunicado não pôde ser arquivado.',
            'ok',
            null
          )
        );
      }
    );
  }

  desarquivarMensagem() {
    this.loading = true;
    const protocolo = this.codigo;
    this.caixaPostalService.desarquivarMensagem(protocolo).subscribe(
      res => {
        this.loading = false;
        Swal.fire(
          getSwal(
            'success',
            'Sucesso!',
            'O comunicado foi desarquivado com êxito.',
            'ok',
            null
          )
        ).then(() => {
          this.router.navigate(['/caixa-postal/mensagens']);
        });

      },
      error => {
        Swal.fire(
          getSwal(
            'info',
            'Ops!',
            'Ocorreu um erro e o comunicado não pôde ser desarquivado.',
            'ok',
            null
          )
        );
      }
    );
  }

  gerarDossie() {
    this.loading = true;
    this.caixaPostalService.gerarDossie(this.codigo).subscribe(response => {
      this.loading = false;
      var blob = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `dossie`);
    });
  }

  gerarTermoCiencia() {
    this.loading = true;
    this.caixaPostalService.gerarTermoCiencia(this.codigo).subscribe(response => {
      this.loading = false;
      var blob = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `termo-ciencia`);
    });
  }

  handleResponse(res) {
    this.mensagem = res.resultado;

    if (res.resultado.tipoComunicado.mensagemPadrao) {
      this.mensagem.situacaoMensagem.dataSituacao = moment(this.mensagem.situacaoMensagem.dataSituacao).format('DD/MM/YYYY [às] HH:mm');
      this.mensagem.respostas = this.mensagem.respostas;

      let codigoUsuario = res.resultado.destinatario.usuario.codigo;
      let cpfCnpj = res.resultado.destinatario.usuario.cpfCnpj;
      this.getDestinatario(codigoUsuario, cpfCnpj);

    } else {
      this.mensagem.situacaoMensagem.dataSituacao = moment(this.mensagem.situacaoMensagem.dataSituacao).format('DD/MM/YYYY [às] HH:mm');
      this.mensagem.respostas = this.mensagem.respostas;
      this.formatMessages(this.mensagem);
    }

    if (this.mensagem.tipoComunicado.respostaBloqueada) {
      this.verificarRespostaBloqueada(
        this.mensagem.tipoComunicado.prazoCiencia,
        this.mensagem.dataEnvio);
    }

    this.loading = false;
    this.caixaPostalService.getListaPaginacaoResposta(this.filtroRespostasPaginacao).subscribe(
      response => {
        this.mensagens = response.resultado;
        this.data["totalDeItems"] = response.totalRegistros;
        this.quantidadeComunicados = this.mensagens.length
        this.index =
          this.mensagens.findIndex(
            item => item['protocolo'] === this.mensagem['protocolo']
          ) + 1;
        if (this.index === 0) {
          this.index = 1
        };
        if (this.filtroRespostasPaginacao['respondidas']) {
          this.index =
            this.mensagens.findIndex(
              item => item['protocolo'] === this.protocoloResposta
            ) + 1;
          if (this.index === 0) {
            this.index = 1
          };
        }
      },
      error => console.log(error)
    );
  }

  verificarRespostaBloqueada(prazoCiencia, dataEnvio) {
    if (prazoCiencia > 0) {
      let dataLimite = moment(dataEnvio).add(prazoCiencia, 'days');
      let dataAtual = moment();
      let verificaBloqueio: boolean = moment(dataLimite).isAfter(dataAtual);
      if (verificaBloqueio == true) {
        this.bloquearResposta = false
      } else {
        this.bloquearResposta = true
      }
    }
  }

  getDestinatario(codigo, cpfCnpj) {
    this.gerenciarUsuariosService.obterUsuario(codigo).subscribe(res => {
      let perfilAcesso = res.perfis;
      perfilAcesso.forEach(data => {
        if (data.codigo == 2) {
          this.cpfCnpjContribuinte = this.pipeDoc.transform(res.cpfCnpj);
          this.nomeRazaoSocialContribuinte = res.nomeRazaoSocial;
        }
        if (data.codigo == 3) {
          this.cpfCnpjProcurador = this.pipeDoc.transform(res.cpfCnpj);
          this.nomeRazaoSocialProcurador = res.nomeRazaoSocial;
        }
      })
    });

    this.procuracoesService.getRepresentanteLegal(cpfCnpj).subscribe(res => {
      this.cpfRepresentanteLegal = this.pipeDoc.transform(res['resultado']['outorgante']['cpfCnpj']);
      this.nomeRazaoSocialRepresentanteLegal = this.pipeDoc.transform(res['resultado']['outorgante']['nomeRazaoSocial']);
    })

    setTimeout(() => {
      this.trocaVariaveisTexto();
    }, 500);
  }

  trocaVariaveisTexto() {

    let dataCurta = moment(this.mensagem.dataEnvio).format('DD/MM/YYYY');
    let dataLonga = moment(this.mensagem.dataEnvio).format('LL');

    this.mensagem.mensagem = this.mensagem.mensagem
      .replace(/@{id do representante}/g, this.cpfRepresentanteLegal)
      .replace(/@{representante}/g, this.nomeRazaoSocialRepresentanteLegal)
      .replace(/@{id do contribuinte}/g, this.cpfCnpjContribuinte)
      .replace(/@{contribuinte}/g, this.nomeRazaoSocialContribuinte)
      .replace(/@{id do procurador}/g, this.cpfCnpjProcurador)
      .replace(/@{procurador}/g, this.nomeRazaoSocialProcurador)
      .replace(/@{data curta}/g, dataCurta)
      .replace(/@{data longa}/g, dataLonga)
      .replace(/null/g, "")
      .replace(/undefined/g, "");

    this.mensagem.mensagem = this.domSanitizer.bypassSecurityTrustHtml(this.mensagem.mensagem);
    this.formatMessages(this.mensagem);
  }

  formatMessages(msg) {
    console.log('mensagem', msg);
    this.messagesList.push(msg);
    msg['respostas'].forEach(element => {
      element['resposta'] = true;
      return this.formatMessages(element);
    });
    return 1;
  }

  checkReplyState(state: boolean) {
    this.alreadyReplying = state;
  }

  replyMessage() {
    ;
    console.log(moment(this.mensagem.situacaoMensagem.dataSituacao).add(this.mensagem.situacaoMensagem.prazo, 'days').format('DD/MM/YYYY HH:mm:ss'));
    console.log(moment(this.dataAtual).format('DD/MM/YYYY HH:mm:ss'));
    console.log(this.dataAtual);

    console.log(this.mensagem.tipoComunicado.respostaBloqueada);

    this.dataFormatada = this.dataAtual;
    this.dataFormatada = moment(this.dataFormatada).format('DD/MM/YYYY HH:mm:ss');
    this.dataBloqueada = moment(this.mensagem.situacaoMensagem.dataSituacao).add(this.mensagem.situacaoMensagem.prazo, 'days').format('DD/MM/YYYY HH:mm:ss');
    if (this.dataFormatada > this.dataBloqueada && this.mensagem.tipoComunicado.respostaBloqueada) {
      this.reply = false
      Swal.fire(
        getSwal(
          'warning',
          '',
          'Prazo para resposta expirou no dia ' + moment(this.mensagem.situacaoMensagem.dataSituacao).add(this.mensagem.situacaoMensagem.prazo, 'days').format('DD/MM/YYYY HH:mm:ss'),
          'ok',
          null
        ),
      );
    } else {
      this.reply = true;
    }
  }
}
