import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class ConsultaUsuarioService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  consultarUsuario(id: string): Observable<any> {
    const InterceptorSkipHeader = 'X-Skip-Interceptor';
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const params = new HttpParams().set('cpfCnpj', id);
    return this.http
      .get(`${this.url}/usuarios`, { headers: headers, params: params })
      .map(data => {
        return data;
      });
  }

  consultarUsuariosAtivos(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/usuarios/ativos`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  consultarContribuintesAtivos(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/usuarios/contribuintes/ativos`, { params: params })
      .pipe( map(data => {
        return data;
      }));
  }

  getPersonalidadesJuridicasAtivos(): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/ativos`).map(data => {
      const body = data;
      return body;
    });
  }

  getSetoresAtuacaoAtivos(): Observable<any> {
    return this.http.get(`${this.url}/setoresAtuacao/ativos`).map(data => {
      const body = data;
      return body;
    });
  }
}
