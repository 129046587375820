import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SessionService } from 'src/app/service/session/session.service';
import { ConsultaUsuarioService } from "src/app/service/consulta-usuario/consultar.user.service";
import { getSwal } from '../../../../../../utils/swalObject';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-filtro-contribuinte',
  templateUrl: 'filtro-contribuinte.component.html',
  styleUrls: ['./filtro-contribuinte.component.scss'],
})
export class FiltroContribuinteComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';
  contribuinteForm: FormGroup;
  @Input()
  visible: string;
  contribuinte: boolean = false;
  numero: any;
  loading: boolean = false;
  isAdmnistrativoEncarregado: boolean = false;
  session: any;
  userLogado: any;

  searchParams: Object = {
    pagina: 1
  };

  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  constructor(
    private fb: FormBuilder,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private consultaUsuario: ConsultaUsuarioService,
  ) { }

  ngAfterViewChecked(): void {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if (sessionStorage.getItem('filtro') && (sessaoFiltro.cpf_cnpj || sessaoFiltro.inscricao)) {
      this.contribuinte = true;
    } else {
      this.contribuinte = false;
    }
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.session = this.sessionService.getSession();
    this.isAdmnistrativoEncarregado = [4, 5].includes(this.session['perfilAcesso'].codigo);

    this.criarFormulario();
    this.verificarFiltroSession();

    this.tipo = 'cpf';
    this.mascara = '000.000.000-00';
    this.contribuinteForm.controls.tipo.setValue(this.tipo);
    this.alterarIdentificador(this.tipo);
  }

  verificarFiltroSession() {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));

    if (sessaoFiltro && (sessaoFiltro.cpf_cnpj || sessaoFiltro.inscricao)) {
      this.contribuinte = true;
    } else {
      this.contribuinte = false;
    }
  }

  criarFormulario() {
    this.contribuinteForm = this.fb.group({
      tipo: [this.tipo],
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)]),
      ],
    });
  }

  alterarIdentificador(tipo: string) {
    this.contribuinteForm.controls.numero.reset();

    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.compose([Validators.required, Validators.minLength(11)])
        );
        break;
      case 'cnpj':
        this.mascara = '00.000.000/0000-00';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.compose([Validators.required, Validators.minLength(14)])
        );
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.compose([Validators.required, Validators.minLength(1)])
        );
        break;
      default:
        this.mascara = '';
        this.contribuinteForm.controls.numero.clearValidators();
        break;
    }
    this.contribuinteForm.controls.numero.updateValueAndValidity();
  }

  consultarCpfValido(cpf) {
    this.loading = true;
    this.consultaUsuario.consultarUsuario(cpf).subscribe(res => {
      this.loading = false;
    }, error => {
      this.loading = false;
      if (error.error.excecao.recomendacao !== '') {
        this.loading = true;
        this.contribuinteForm.controls.numero.reset();
        this.limparFiltros()
        !this.contribuinteForm.controls.numero.valid
        Swal.fire(
          getSwal(
            'error',
            '',
            error.error.excecao.recomendacao,
            'ok',
            null
          )
        );
        this.contribuinteForm.controls.numero.setErrors({ invalido: true })
        this.contribuinteForm.controls.numero.reset();

      }
      if (error.error.excecao.mensagem == "Dado não encontrado") {
        !this.contribuinteForm.controls.numero.valid
        this.contribuinteForm.controls.numero.reset();
        this.limparFiltros()
        Swal.fire(
          getSwal(
            'error',
            '',
            error.error.excecao.mensagem,
            'ok',
            null
          )
        );
        this.contribuinteForm.controls.numero.reset();
        this.limparFiltros();
      } else {
        this.contribuinteForm.controls.numero.setErrors(null)
      }
    })
  }


  limparFiltros() {
    const tipos =
      this.contribuinteForm.controls.tipo.value === 'inscricao'
        ? 'inscricao'
        : 'cpf_cnpj';
    this.onClear.emit({
      tipo: tipos,
      numero: '',
    });
    var obj = {};
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.data['paginaAtual'] = 1

    this.contribuinte = false;
    this.contribuinteForm.reset();
    this.searchParams['pagina'] = 1;

    sessionStorage.removeItem('filtro');

    this.tipo = 'cpf';
    this.contribuinteForm.controls.tipo.setValue(this.tipo);
    this.alterarIdentificador(this.tipo);
    this.loading = false;
  }

  pesquisarContribuinte() {
    this.loading = true;
    this.contribuinte = true;
    const value = this.contribuinteForm.value;
    let obj = {};
    if (value.tipo === 'inscricao') {
      obj['inscricao'] = value.numero;
      obj['cpf_cnpj'] = '';
    } else {
      this.consultarCpfValido(value.numero);
      obj['inscricao'] = '';
      obj['cpf_cnpj'] = value.numero;
    }

    if (
      obj['inscricao'].toString().length < 2 &&
      obj['cpf_cnpj'].toString().length < 2
    ) {
      this.contribuinte = false;
    }

    this.onFilter.emit(obj);

    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.data['pagina'] = 1

    this.loading = false;
  }

  resetarParametros() {
    this.contribuinteForm.controls.numero.reset();
    this.onClear.emit({
      tipo: '',
      numero: '',
    });

    this.searchParams['pagina'] = 1;
    this.tipo = 'cpf';
    this.numero = null;

    let obj = {}
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.data['paginaAtual'] = 1
    this.limparFiltros();
  }
}
