import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared.module';
import { CommonAppModule } from './features/common/common.module';
import { RegistroAcoesModule } from './features/registro-acoes/registro-acoes.module';
import { LoginModule } from './features/login/login.module';
import { HomeModule } from './features/home/home.module';
import { GerenciarComunicadosModule } from './features/gerenciar-comunicados/gerenciar-comunicados.module';
import { CaixaPostalEletronicaModule } from './features/caixa-postal-eletronica/caixa-postal-eletronica.module';
import { ConfiguracaoModule } from './features/configuracao/configuracao.module';
import { AppRoutingModule } from './app.routing.module';
import { InlineSVGModule } from 'ng-inline-svg';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './features/not-found/not-found.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { IntegracaoModule } from './features/integracao/integracao.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SvgInitializer } from './utils/svg.initializer';

import { GraficosFuncionalidadesComponent } from './features/configuracao/gerenciar-sistemas/componentes/graficos-funcionalidades/graficos-funcionalidades.component';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { I18nConfiguration } from './utils/i18n.config';
import { I18nInitializer } from './utils/i18n.initializer';
import { ThemeModule, sefazTheme, decTheme, dteTheme } from './theme';
import { environment } from '../environments/environment';
import { AnaliseDadosModule } from './features/analise-dados/analise-dados.module';
import { GestaoUsuarioModule } from './features/gestao-usuario/gestao-usuario.module';

registerLocaleData(localePt);

/**
 * Factory para carregamento dos arquivos de i18n
 * @param httpClient
 */
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.i18nPath, '.json');
}

export class SvgEndpoint {
  getConfig() {
    return { svg_sprites: [`${environment.assetsPath}/images/sprite.svg`] };
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    // GraficosFuncionalidadesComponent,
  ],
  // exports: [GraficosFuncionalidadesComponent],
  imports: [
    CaixaPostalEletronicaModule,
    CommonModule,
    CommonAppModule,
    InlineSVGModule,
    LoginModule,
    HomeModule,
    SharedModule,
    RouterModule,
    RegistroAcoesModule,
    IntegracaoModule,
    GerenciarComunicadosModule,
    ConfiguracaoModule,
    AnaliseDadosModule,
    BrowserModule,
    BrowserAnimationsModule,
    GestaoUsuarioModule,
    AppRoutingModule,
    // ThemeModule.forRoot({
    //   themes: [dteTheme, sefazTheme, decTheme],
    //   active: 'dte',
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    HttpClientModule,
    TranslateService,
    I18nConfiguration,
    SvgEndpoint,
    // { provide: ErrorHandler, useClass: EiconErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: I18nInitializer,
      deps: [TranslateService, I18nConfiguration],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SvgInitializer,
      deps: [HttpClient, SvgEndpoint],
      multi: true,
    },
  ],
})
export class AppModule {}
